select:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.header {
  padding: 0 1rem !important;
  height: 50px;
  background: #4452fb;
  position: fixed !important;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.headerlearnmore {
  color: #fff;
  padding: 13px 15px;
  font-size: 16px;
  font-weight: 500;
  margin-top: 15px;
  cursor: pointer;
  transition: all 0.3s;
}
@media only screen and (max-width: 1000px) {
  .headerlearnmore {
    padding: 0;
    margin-top: 5px;
  }
  .navbar-collapse {
    background-color: #4452fb;
  }
}

.changepassword_faicons {
  cursor: pointer;
  position: absolute;
  right: 14px;
  top: 14px;
  color: #3947e6;
}
/* -------------->Error css stylesheet start<--------------- */
.errormessage {
  position: relative;
  color: #ff0000;
  font-weight: bold;
  display: flex;
  font-family: "Quicksand";
  margin-left: 12px;
}
.errorifont {
  color: white;
  background: red;
  border-radius: 50%;
  padding: 2px;
  font-size: 10px;
  margin-right: 2px;
  position: absolute;
  top: 6px;
  left: -13px;
}
/* -------------->Error css stylesheet End<--------------- */

/* -------------->Loader css stylesheet<--------------- */

#preloader {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition-duration: 0.45s;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
#prespinner {
  width: 72px;
  height: 72px;
  border-radius: 50%;
  border-width: 10px;
  border-style: solid;
  border-color: #cfcfda;
  border-top-color: #1abcfe;
  animation: spin 1.5s infinite linear;
}

.loader_text {
  font-weight: bold;
  font-size: 25px;
  color: #4452fb;
}
/* -------------->Loader css stylesheet<--------------- */

/* -------------->Login Page stylesheet Started<--------------- */
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}
.login_text {
  color: #4452fb;
  font-size: 27px;
  font-weight: 700;
  margin-bottom: 20px;
}
.login_container {
  box-shadow: 0 0 4px #c4c4c4;
  min-width: 500px;
  border-radius: 4px;
  background: #fff;
  padding: 20px;
}
@media only screen and (max-width: 600px) {
  .login_container {
    min-width: 300px;
  }
}
@media only screen and (max-width: 768px) {
  .row {
    flex-direction: column !important;
  }
}
.display_form .MuiFormControl-root {
  width: 100%;
  border: 0px !important;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  font-size: 24px;
  color: #3947e6;
  font-family: "Quicksand";
  margin: 20px 0;
  position: relative;
}
.display_form .form-control {
  border: 0px !important;
  border-bottom: 1px solid #d1d1d1 !important;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  font-size: 20px;
  color: #3947e6;
  font-family: "Quicksand";
  padding: 0px 32px 10px 0px;
  margin: 0px;
  position: relative;
  font-weight: bolder;
}
.display_form .MuiFormLabel-root {
  font-size: 15px !important;
}
.login_faicons {
  cursor: pointer;
  position: absolute;
  right: 14px;
  top: 36px;
  color: #3947e6;
}

.login_btn {
  background: #4452fb !important;
  color: #fff !important;
  text-align: center !important;
  margin: 0 auto !important;
  margin-top: 30px !important;
  border-radius: 60px !important;
  padding: 9px 52px !important;
}
.loginerrormessage {
  position: relative;
  color: #ff0000;
  font-weight: bold;
  display: flex;
  font-family: "Quicksand";
  margin-left: 12px;
  margin-top: -15px;
}
.forgotpasswordtext {
  text-align: end;
  cursor: pointer;
  color: #3947e6;
  margin-top: -10px;
}
/* -------------->Login Page stylesheet Ended<--------------- */

/* -------------->Display Page stylesheet Start<--------------- */
.display_btn {
  background: #4452fb !important;
  color: #fff !important;
  text-align: center !important;
  margin-top: 30px !important;
  margin-right: 30px !important;
  border-radius: 60px !important;
  padding: 9px 52px !important;
  height: 43px !important;
  font-weight: bold !important;
}
.displaytablerow {
  margin: 20px 20px !important;
}
.displaynodataavailabletext {
  font-size: 25px;
  color: #3947e6;
}
.displaytable_minwidth {
  min-width: 200px !important;
}
.displaytable_bold {
  font-weight: bolder !important;
  font-family: "Quicksand" !important;
}
/* -------------->Display Page stylesheet End<--------------- */

/* -------------->Compare Page stylesheet Start<--------------- */

.comparedata_sorticon {
  cursor: pointer;
  margin: 0 7px;
}

/* -------------->Compare Page stylesheet End<--------------- */

/* -------------->PageScroll Arrow stylesheet Start<--------------- */
.startingdiv {
  position: absolute;
  top: 0;
}
.amazon_scrollarrow {
  position: fixed;
  bottom: 22px;
  right: 20px;
  color: blue;
  opacity: 0.5;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.amazon_scrollarrow2 {
  position: fixed;
  bottom: 22px;
  right: 40px;
  color: blue;
  opacity: 0.5;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
}
.amazon_scrollarrow:hover,
.amazon_scrollarrow2:hover {
  opacity: 1;
  font-size: 30px;
}
/* -------------->PageScroll Arrow stylesheet End<--------------- */
